// 供应商信息管理
<template>
    <div class='customerInfoManage baseBg'>

        <el-container>
            <el-aside>
                <comSupplierLeftContainer @checkTreeType="checkTreeType"
                                          :checkTreeData="checkTreeData"
                                          :treeType="treeType"
                                          @checkTree="checkTree"></comSupplierLeftContainer>
            </el-aside>
            <el-main>
                <supplierDetail :treeType="treeType"
                                :checkTreeData="checkTreeData"></supplierDetail>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import comSupplierLeftContainer from "./components/supplierInformation/comSupplierLeftContainer";
import supplierDetail from "./supplierDetail";
// import comUserManagementRightTable from "./components/userManagement/comUserManagementRightTable";

export default {
    name: "supplierInfoManages",

    props: [],
    watch: {},
    components: {
        comSupplierLeftContainer,
        supplierDetail,
    },

    data() {
        return {
            activeName: "companyInfo",
            checkTreeData: null,
            treeType: "PROVIDER",
        };
    },

    created() {},

    mounted() {
        //是否通过路由跳转携带参数  进行详情查看
        this.isRouteGo();
    },

    methods: {
        //查看对应的供应商详情
        isRouteGo() {
            let data = this.$route.query.data;
            if (data && data !== "{}") {
                let prame = JSON.parse(data);
                this.treeType = prame.treeType;
                this.checkTreeData = { id: prame.id,name:prame.name };
            }
        },

        handleClick() {},

        //当前选中树的数据
        checkTree(data) {
            this.checkTreeData = data;
        },

        // 客户/供应商类型切换
        checkTreeType(data) {
            this.treeType = data.type;
        },
    },

    computed: {},
};
</script>
<style lang='scss' scoped>
.customerInfoManage {
    padding: 20px;
}
.filter-tree {
    overflow: auto;
}
.operation-box {
    visibility: hidden;
}
.el-tree-node__content:hover .operation-box {
    visibility: visible;
}
.content-box {
    padding: 10px;
}
h6 {
    margin: 10px 0;
}
.el-container {
    min-height: calc(100vh - 162px);
}
.el-main {
    padding-top: 0;
    padding-bottom: 0;
}
.el-aside {
    width: 300px !important;
}
</style>

